var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = true;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;


var subsiteHijackMainNav = ['rulku', 'island-and-cape', 'bukmak', 'dinybulu'];
var subsiteHijackMainLogoClickDestination = ['rulku', 'island-and-cape', 'bukmak', 'dinybulu'];

// Subsites - Social icons redirect
if ($("body").hasClass("subsite") && $("body").hasClass("rulku")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/RulkuEnterprises/");
  $(".cta-button.cta-contact").attr("href", "/contact-us-rulku");
}

if ($("body").hasClass("subsite") && $("body").hasClass("island-and-cape")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/IslandandCape/");
  $(".cta-button.cta-contact").attr("href", "/contact-us-island-and-cape");
}

if ($("body").hasClass("subsite") && $("body").hasClass("bukmak")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/BukmakConstructions/");
  $(".cta-button.cta-contact").attr("href", "/contact-us-bukmak");
}

if ($("body").hasClass("subsite") && $("body").hasClass("dinybulu")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/Dinybulu/ ");
  $(".cta-button.cta-contact").attr("href", "/contact-us-dinybulu");
}

if ($("body").hasClass("homepage")) {
  $('.carousel').wrap('<div class="carouselWrapper"></div>');
}


$('document').ready(function () {
  
  if ($('body.subsite').length) {
    // $('.carouselSlide img').each(function () {
    //   $(this).attr('srcset', '');
    //   $(this).attr('src', $(this).attr('src').replace('width=800&height=800', 'width=1000&height=500'));
    // });
    $('.subsiteBody').appendTo('.headerTextSubsite');
    $('.headerTextSubsite').appendTo('.carouselSlide');
  }

})